/* .gradient-red {
  background-image: url('https://kids.digitalnawab.com/images/class1.webp');
} */
.contents {
    margin: 20px 70px;
}
.color-white{
    color: rgb(24, 39, 175);
}
.selectsubjectsize{
  margin-top: -27px !important;
}
#footer-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 98;
  background-color: rgba(249, 249, 249, 0.98);
  box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.06);
  min-height: 60px;
  height: calc(62px + (constant(safe-area-inset-bottom)) * 1.1);
  height: calc(62px + (env(safe-area-inset-bottom)) * 1.1);
  display: flex;
  text-align: center;
}

#footer-bar a {
  color: #1f1f1f;
  padding-top: 12px;
  position: relative;
  flex: 1 1 auto;
}

#footer-bar a span {
  position: relative;
  z-index: 2;
  display: block;
  font-size: 10px;
  font-weight: 500;
  margin-top: -6px;
  opacity: 0.7;
  font-family: Roboto, sans-serif !important;
}

#footer-bar a i {
  font-size: 18px;
  position: relative;
  z-index: 2;
}

#footer-bar .badge {
  font-style: normal;
  z-index: 5;
  top: 0;
  position: absolute;
  margin-left: 3px;
  color: #fff !important;
  width: 18px;
  text-align: center;
  line-height: 18px;
  padding: 0;
  padding-left: 0 !important;
  border-radius: 18px;
  margin-top: 7px;
  font-size: 11px;
}

.footer-bar-2 .active-nav {
  color: #fff !important;
}

.footer-bar-2 .active-nav strong {
  position: absolute;
  width: 80px;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  bottom: 0;
}

.footer-bar-4 .active-nav {
  color: #fff !important;
}

.footer-bar-4 .active-nav strong {
  position: absolute;
  width: 47px;
  height: 47px;
  border-radius: 60px;
  left: 50%;
  top: 30px;
  transform: translate(-50%, -50%);
  bottom: 0;
}

.footer-bar-4 span {
  display: none !important;
}

.footer-bar-4 i {
  padding-top: 10px;
}

.footer-bar-5 .active-nav strong {
  position: absolute;
  width: 50px;
  height: 2px;
  border-radius: 60px;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

.footer-bar-3 span {
  display: none !important;
}

.footer-bar-3 .active-nav {
  padding-top: 11px !important;
}

.footer-bar-3 .active-nav span {
  display: block !important;
}

.footer-bar-3 a {
  padding-top: 18px !important;
}

.footer-menu-hidden {
  transition: all 0.1s ease;
  transform: translateY(100%) !important;
}

.footer-bar-white * {
  color: #fff;
}

#footer-bar.position-relative {
  z-index: 2 !important;
}

.is-ios #footer-bar {
  height: calc(65px + (constant(safe-area-inset-bottom)) * 1.1);
  height: calc(65px + (env(safe-area-inset-bottom)) * 1.1);
}

.is-not-ios .footer-menu-clear {
  height: 70px;
  display: block;
}

.is-not-ios .footer {
  padding-bottom: 0;
}

.is-not-ios #footer-menu a i {
  padding-top: 13px;
}

.is-not-ios #footer-menu a span {
  opacity: 0.6;
}

.footer-bar-scroll {
  display: block !important;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  margin: 0;
  padding: 0;
}

.footer-bar-scroll a {
  display: inline-block;
  width: 19.5%;
}

.footer-bar-scroll.footer-bar-4 .active-nav strong {
  margin-top: 1px;
}

.footer-bar-scroll.footer-bar-4 .badge {
  margin-left: -8px !important;
  margin-top: 14px !important;
}

.footer-bar-scroll.footer-bar-3 .badge {
  margin-left: -7px !important;
  margin-top: 0 !important;
}

.footer-bar-scroll.footer-bar-3 i {
  transform: translateY(-10px);
}

.footer-bar-scroll.footer-bar-3 .active-nav i {
  transform: translateY(0);
}
.footer-bar-1 .active-nav i,
.footer-bar-1 .active-nav span,
.footer-bar-3 .active-nav i {
  color: #da4453 !important;
}

/*  background */
#page {
  /* background-image: linear-gradient(0deg, #f2f2f7, #9696dc) !important; */
}

#page {
  position: relative;
}

.page-content {
  overflow-x: hidden;
  transition: all 350ms ease;
  z-index: 90;
  /* padding-bottom: 80px;
  padding-bottom: calc(80px + (constant(safe-area-inset-bottom)) * 1.1);
  padding-bottom: calc(80px + (env(safe-area-inset-bottom)) * 1.1); */
}
.splide {
  visibility: hidden;
}

.splide,
.splide__slide {
  position: relative;
  outline: 0;
}

.splide__slide {
  box-sizing: border-box;
  list-style-type: none !important;
  margin: 0;
  flex-shrink: 0;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__slider {
  position: relative;
}
.splide__track {
  position: relative;
  z-index: 0;
}

.splide--draggable > .splide__track > .splide__list > .splide__slide {
  -webkit-user-select: none;
  user-select: none;
}

.splide--fade > .splide__track > .splide__list {
  display: block;
}

.splide--fade > .splide__track > .splide__list > .splide__slide {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
}

.splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
  position: relative;
  z-index: 1;
  opacity: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide--ttb > .splide__track > .splide__list {
  display: block;
}
.splide__list {
  margin: 0 !important;
  padding: 0 !important;
  width: -webkit-max-content;
  width: max-content;
  will-change: transform;
}

.splide.is-active .splide__list {
  display: flex;
}
.splide,
.splide__slide {
  position: relative;
  outline: 0;
}

.splide__slide {
  box-sizing: border-box;
  list-style-type: none !important;
  margin: 0;
  flex-shrink: 0;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__slider {
  position: relative;
}
.cards {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card-bottom {
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  /* bottom: 0; */
}
.opacity-80 {
  opacity: 0.8 !important;
}
.card-style .card-overlay {
  border-radius: 15px;
}

.banner-mobile {
  border-radius: 12px;
}
.logo-row-mobile {
  text-align: center;
}
.logo-mobile {
  width: 250px;
  height: 100px;
  text-align: center;
}
.card-top {
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.header-clear-medium {
  padding: 20px;
}
.bg-red-dark {
  background-image: url("https://kids.digitalnawab.com/images/home_image/teacher.png");
  color: #fff !important;
  background-size: cover;
  background-position: 50% 40px !important;
  height: 240px;
}
.bg-red-dark2 {
  background-image: url("../../../../public/images/stu_mo.webp");
  color: #fff !important;
  background-size: cover;
  background-position: 50% 40px !important;
  height: 240px;
}
.card {
  -webkit-transform: translate3d(0, 0, 0);
  background-size: cover;
  border: 0 !important;
  max-height: 150000px;
  margin-bottom: 30px;
  background-position: center center !important;
}
.card-style {
  overflow: hidden;
  margin: 0 6px 10px;
  border-radius: 15px;
  border: none;
  box-shadow: 0 4px 24px 0 rgb(0 0 0 / 8%);
}
.color-highlight {
  color: #da4453 !important;
}

.bg-highlight {
  background-color:#FF0080 !important;
  color: #fff !important;
  width: 100%;
  border-radius: 0px !important;
}
.bg-highlights {
  background-color: #1877f2 !important;
  color: #fff !important;
  width: 100%;
  border-radius: 0px !important;
}
.bg-18 {
  background-image: url("https://kids.digitalnawab.com/images/home_image/teacher.png");
}

.contents {
  margin: 20px 15px;
}

@media only screen and (max-width: 600px) {
  .classes {
    background-image: none !important;
    height: 90px;
    background-color: #c4ddc5;
    background-blend-mode: overlay;

    /* border-radius: 12px; */
  }
  .schedule {
    margin: 4px;
    overflow: hidden;
  }
  .schedule li {
    list-style: none;
    /* display: inline-block; */
    float: left;
    padding: 10px;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    width: 33.335%;
    background-color: #92278f;
    color: #fff;
    border-top: 1px solid #fafafa;
    border-right: 1px solid #fafafa;
  }
  .schedule li:nth-child(2) {
    background-color: #e84b3a;
  }
  .schedule li:last-child {
    background-color: #fc7f0c;
    border-right: none;
  }

  .content h4 a {
    color: #e84b3a;
  }
  #loader {
    margin: auto;
    top: 30%;

    width: 400px;
    height: 400px;
    text-align: center;
    background-size: 100%;
  }
}
